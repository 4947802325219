export const settingsList = [
  {
    title: "Merchants",
    icon: "description",
    sub: [
      {
        title: "Merchant",
        route: "merchant-list",
        desc: "View merchants",
      },
    ],
  },
  {
    title: "Enabled products",
    icon: "description",
    sub: [
      {
        title: "Enabled products",
        route: "product-list",
        desc: "View the product view configuration required for integrations using the products enabled on your account",
      },
    ],
  },
  {
    title: "Settings",
    icon: "description",
    sub: [
      {
        title: "Logo",
        route: "logo-library",
        desc: "Upload & replace logos",
      },
      {
        title: "Users",
        route: "user-list",
        desc: "View, edit & create users",
      },
      {
        title: "Platform profile",
        route: "platform-profile",
        desc: "Configure platform profile",
      },
    ],
  },
];
