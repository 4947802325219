<template>
  <div class="main-settings req-temp-container mt-6 md:mt-8 lg:mt-12">
    <div v-for="item in settings" :key="item.title">
      <link-card :item="item" class="card-style" />
    </div>
  </div>
</template>

<script>
import LinkCard from "./../../components/LinkCard.vue";
import { settingsList } from "./settings/settingsList.js";
import { mapActions } from "vuex";

export default {
  components: { LinkCard },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  },
  data() {
    return {
      loggedInUser: "",
      settings: settingsList,
    };
  },
  methods: {
    ...mapActions("admin", ["checkLogin"]),
    async checkAdminLogin() {
     await this.checkLogin();
    },
  },
  created() {
    this.loggedInUser = JSON.parse(localStorage.getItem("userInfo"));
    this.checkAdminLogin();
  },
};
</script>