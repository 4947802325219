<template>
  <vs-card class="mb-4 md:mb-6 lg:mb-8">
    <div slot="header">
      <div class="flex items-center">
        <h3 class="flex items-center font-normal text-lg lg:text-xl">
          <svg
            class="mr-4"
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3332 15.4671C10.4943 15.4671 10.6248 15.3365 10.6248 15.1755C10.6248 15.0144 10.4943 14.8838 10.3332 14.8838C10.1721 14.8838 10.0415 15.0144 10.0415 15.1755C10.0415 15.3365 10.1721 15.4671 10.3332 15.4671Z"
              stroke="#BDBDBD"
              stroke-width="0.583333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.5417 15.4671C13.7028 15.4671 13.8333 15.3365 13.8333 15.1755C13.8333 15.0144 13.7028 14.8838 13.5417 14.8838C13.3806 14.8838 13.25 15.0144 13.25 15.1755C13.25 15.3365 13.3806 15.4671 13.5417 15.4671Z"
              stroke="#BDBDBD"
              stroke-width="0.583333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 9.3421H9.16667L9.94833 13.2475C9.975 13.3818 10.0481 13.5024 10.1547 13.5883C10.2613 13.6741 10.3948 13.7197 10.5317 13.7171H13.3667C13.5035 13.7197 13.637 13.6741 13.7436 13.5883C13.8503 13.5024 13.9233 13.3818 13.95 13.2475L14.4167 10.8004H9.45833"
              stroke="#BDBDBD"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="1"
              y="3.85092"
              width="21"
              height="14.8912"
              rx="1"
              stroke="#BDBDBD"
              stroke-width="2"
            />
            <path
              d="M1 2.3421C1 1.78982 1.44772 1.3421 2 1.3421H21C21.5523 1.3421 22 1.78982 22 2.3421V6.20721H1V2.3421Z"
              fill="white"
            />
            <line
              x1="3.98086"
              y1="3.64829"
              x2="6.00281"
              y2="3.64829"
              stroke="#BDBDBD"
              stroke-width="1.26372"
              stroke-linecap="round"
            />
            <line
              x1="8.53018"
              y1="3.64829"
              x2="19.0191"
              y2="3.64829"
              stroke="#BDBDBD"
              stroke-width="1.26372"
              stroke-linecap="round"
            />
            <path
              d="M1 2.3421C1 1.78982 1.44772 1.3421 2 1.3421H21C21.5523 1.3421 22 1.78982 22 2.3421V6.20721H1V2.3421Z"
              stroke="#BDBDBD"
              stroke-width="2"
            />
          </svg>
          {{ item.title }}
        </h3>
      </div>
    </div>
    <vs-row class="card-desc settings-grid onboard-settings-grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 px-0 md:px-8 py-0 md:py-2 lg:py-4">
      <div v-for="(subitem, index) in item.sub" :key="subitem.title">
        <div
          :class="`settings-grid-container ${index > 1 ? 'last-vline' : ''}`"
        >
          <div class="flex items-center justify-between settings-grid-option">
            <div class="pr-2">
              <h5 class="mb-2 font-base" @click="gotoPage(subitem.route)">
                {{ subitem.title }}
              </h5>
              <p class="text-xs font-normal leading-normal" @click="gotoPage(subitem.route)">
                {{ subitem.desc }}
              </p>
            </div>
            <div>
              <a @click="gotoPage(subitem.route)">
                <feather-icon
                  icon="ChevronRightIcon"
                  class="cursor-pointer align-bottom"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    gotoPage(route) {
      this.$router.push({
        name: route,
      });
    },
  },
};
</script>
